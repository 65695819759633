import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/cloud/thingos-homepage/packages/meshlighting/src/components/BlogLayout.tsx";
import { graphql } from 'gatsby';
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		alexa: file(relativePath: { eq: "blog-posts/example-alexa.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In einer Zeit, in der pandemiebedingt Menschen vermehrt zuhause waren, stiegen die Investitionen in die eigenen vier Wände kräftig. Davon profitieren nebst Möbelherstellern, der Gartenbranche auch die Smart Home Industrie, wie eine Studie der `}<a style={{
        "color": "rgba(255,255,255,0.5)"
      }} target="_blank" href="https://www.beyto.com/smart-speaker-studie-2020/">{`Beyto GmbH`}</a>{` zeigt.`}</p>
    <h4>{`Jeder vierte Deutsche besitzt inzwischen einen Smart Speaker`}</h4>
    <p>{`Von 2018 bis 2020 stieg der Anteil derjenigen, die einen Sprachassistenten nutzten, von 17% auf stolze 26%. Eine beachtliche Steigerung von 61% innerhalb von 2 Jahren. Somit steuert heute jeder Vierte sein Zuhause per Sprache. Die Hälfte davon sogar täglich.`}</p>
    <h4>{`Warum dominiert Amazon den Smart Speaker Markt?`}</h4>
    <p>{`Der Marktanteil teilt sich hauptsächlich auf drei Hersteller auf - Amazon mit dem größten Anteil von 67%, Google mit 17% und Apple mit 8 %. Andere Hersteller teilen sich die restlichen 8%. Aber weshalb hat Amazon einen so großen Abstand zu seinen Konkurrenten? Um diese Frage adäquat beantworten zu können, lohnt sich ein Blick in die Vergangenheit. Zum einen war Amazon Ende 2016 der erste Anbieter von Sprachassistenten in Deutschland. Early Adopter hatten keine andere Wahl als das Gerät des Online-Versandhändlers zu kaufen. Zusätzlich subventionierte Amazon ihre Alexa schon damals kräftig. Laut eines Berichtes (`}<a style={{
        "color": "rgba(255,255,255,0.5)"
      }} target="_blank" href="https://www.computerbild.de/artikel/cb-News-Vernetztes-Wohnen-Amazon-Echo-Dot-Google-Home-Mini-Verluste-19582509.html">{`Quelle`}</a>{`) aus dem Jahr 2018 lag der Materialpreis des Echo Dots, der kleineren Ausführung aus der Alexa Familie, bei umgerechnet 26€. Hinzu kommen Kosten für Herstellung, Verpackung, Versand und Marketing. Zu Aktionszeiten gibt es den Amazon Echo oftmals schon für unter 20€. Es verwundert also nicht, dass bei den niedrigen Preisen nicht nur nichts bei dem Anbieter ankommt, sondern sogar darauf gelegt werden muss. Der Verlust ist jedoch kalkulierbar, denn das eigentliche Ziel ist eine hohe Kundenbindung. Wer bereits einen Sprachassistenten hat, muss deutliche Vorteile bei der Konkurrenz sehen, um zu wechseln. Ein weiterer Grund ist die ausgereifte Spracherkennung. Anderes als bei dem Sprachassistenten Siri von Apple, erkennt Alexa mehr natürlich gesprochene Sätze, so dass der Nutzer neben dem Kommando „Stelle den Timer auf 10 Minuten“ auch „In 10 Minuten ist das Essen fertig, erinnere mich daran!“ sagen kann.`}</p>
    <h4>{`Der Größte entscheidet sich für Bluetooth® Mesh!`}</h4>
    <p>{`Bluetooth® Mesh, die Technologie die auf dem weltweit bekannten Bluetooth® Standard aufbaut, hält Einzug in das Alexa Ökosystem. Es ist nun mit allen gängigen Alexas (ausgenommen der ersten beiden Generationen) möglich, Bluetooth® Mesh Geräte mit der Alexa App zu koppeln und zu steuern. Die Interoperabilität zeigt hier besonders ihre Stärke, da die Bluetooth® Mesh Spezifikation kaum Spielräume für Interpretationen lässt, so dass jedes Bluetooth® Mesh Gerät optimalerweise direkt mit Alexa Geräten kompatibel ist.`}</p>
    <p>{`Als Bluetooth® Mesh Spezialist begrüßen wir die Nachricht außerordentlich und freuen uns, bestehende und auch zukünftige Produkte fortan über Alexa steuern zu können. Gleichzeitig zeigt dies das enorme Potential, welches die Bluetooth® Mesh Technologie für Smart Home Geräte bietet. Der Schritt des Online-Versandhändlers wird sicher auch das Interesse der Konkurrenten Google und Apple auf sich gezogen haben.`}</p>
    <p>{`Für den Fall, dass auch Sie Ihre Produkte Bluetooth® Mesh fähig machen wollen, nehmen Sie gerne Kontakt zu uns auf. Wir unterstützen Sie mit unserer Erfahrung und begleiten Sie von der Idee bis zum fertigen Produkt.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      